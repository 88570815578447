export const ERROR = 'ERROR'

export const TX_SUBMITTED = 'TX_SUBMITTED'
export const TX_RECEIPT = 'TX_RECEIPT'
export const TX_CONFIRMED = 'TX_CONFIRMED'
export const TX_UPDATED = 'TX_UPDATED'

export const SNACKBAR_ERROR = 'SNACKBAR_ERROR'
export const SNACKBAR_TRANSACTION_HASH = 'SNACKBAR_TRANSACTION_HASH'
export const SNACKBAR_TRANSACTION_RECEIPT = 'SNACKBAR_TRANSACTION_RECEIPT'
export const SNACKBAR_TRANSACTION_CONFIRMED = 'SNACKBAR_TRANSACTION_CONFIRMED'
export const SNACKBAR_MESSAGE = 'SNACKBAR_MESSAGE'

export const START_LOADING = 'START_LOADING'
export const STOP_LOADING = 'STOP_LOADING'

export const ETHERSCAN_URL = 'https://etherscan.io/'
export const ADDRESS = 'address/'
export const TRANSACTION = 'tx/'



/* ACCOUNT */
export const CONNECTION_CONNECTED = 'CONNECTION_CONNECTED'
export const CONNECTION_DISCONNECTED = 'CONNECTION_DISCONNECTED'
export const ACCOUNT_CHANGED = 'ACCOUNT_CHANGED'

export const GET_BALANCES = 'GET_BALANCES'
export const BALANCES_RETURNED = 'BALANCES_RETURNED'

export const GET_GAS_PRICES = 'GET_GAS_PRICES'
export const GAS_PRICES_RETURNED = 'GAS_PRICES_RETURNED'

export const GET_CURRENT_BLOCK = 'GET_CURRENT_BLOCK'
export const CURRENT_BLOCK_RETURNED = 'CURRENT_BLOCK_RETURNED'


/* CURRENCIES */
export const CALCULATE_MINT = 'CALCULATE_MINT'
export const CALCULATE_MINT_RETURNED = 'CALCULATE_MINT_RETURNED'

export const MINT = 'MINT'
export const MINT_RETURNED = 'MINT_RETURNED'

export const STAKE = 'STAKE'
export const STAKE_RETURNED = 'STAKE_RETURNED'

export const GET_REWARDS_AVAILABLE = 'GET_REWARDS_AVAILABLE'
export const REWARDS_AVAILABLE_RETURNED = 'REWARDS_AVAILABLE_RETURNED'

export const GET_REWARD = 'GET_REWARD'
export const REWARD_RETURNED = 'REWARD_RETURNED'


/* GOVERNANCE */
export const PROPOSE = 'PROPOSE'
export const PROPOSE_RETURNED = 'PROPOSE_RETURNED'

export const GET_PROPOSALS = 'GET_PROPOSALS'
export const GET_PROPOSALS_RETURNED = 'GET_PROPOSALS_RETURNED'

export const VOTE_FOR = 'VOTE_FOR'
export const VOTE_FOR_RETURNED = 'VOTE_FOR_RETURNED'

export const VOTE_AGAINST = 'VOTE_AGAINST'
export const VOTE_AGAINST_RETURNED = 'VOTE_AGAINST_RETURNED'

export const SIGN_ACTION = 'SIGN_ACTION'
export const SIGN_ACTION_RETURNED = 'SIGN_ACTION_RETURNED'


/* KEEPERS */
export const GET_KEEPERS = 'GET_KEEPERS'
export const KEEPERS_RETURNED = 'KEEPERS_RETURNED'

export const GET_JOBS = 'GET_JOBS'
export const JOBS_RETURNED = 'JOBS_RETURNED'

export const GET_KEEPER = 'GET_KEEPER'
export const KEEPER_RETURNED = 'KEEPER_RETURNED'

export const ADD_BOND = 'ADD_BOND'
export const ADD_BOND_RETURNED = 'ADD_BOND_RETURNED'

export const REMOVE_BOND = 'REMOVE_BOND'
export const REMOVE_BOND_RETURNED = 'REMOVE_BOND_RETURNED'

export const ACTIVATE_BOND = 'ACTIVATE_BOND'
export const ACTIVATE_BOND_RETURNED = 'ACTIVATE_BOND_RETURNED'

export const WITHDRAW_BOND = 'WITHDRAW_BOND'
export const WITHDRAW_BOND_RETURNED = 'WITHDRAW_BOND_RETURNED'

export const GET_KEEPER_PROFILE = 'GET_KEEPER_PROFILE'
export const KEEPER_PROFILE_RETURNED = 'KEEPER_PROFILE_RETURNED'

export const SLASH = 'SLASH'
export const SLASH_RETURNED = 'SLASH_RETURNED'


export const ADD_JOB = 'ADD_JOB'
export const ADD_JOB_RETURNED = 'ADD_JOB_RETURNED'

export const GET_JOB_PROFILE = 'GET_JOB_PROFILE'
export const JOB_PROFILE_RETURNED = 'JOB_PROFILE_RETURNED'

export const ADD_LIQUIDITY_TO_JOB = 'ADD_LIQUIDITY_TO_JOB'
export const ADD_LIQUIDITY_TO_JOB_RETURNED = 'ADD_LIQUIDITY_TO_JOB_RETURNED'

export const APPLY_CREDIT_TO_JOB = 'APPLY_CREDIT_TO_JOB'
export const APPLY_CREDIT_TO_JOB_RETURNED = 'APPLY_CREDIT_TO_JOB_RETURNED'

export const UNBOND_LIQUIDITY_FROM_JOB = 'UNBOND_LIQUIDITY_FROM_JOB'
export const UNBOND_LIQUIDITY_FROM_JOB_RETURNED = 'UNBOND_LIQUIDITY_FROM_JOB_RETURNED'

export const REMOVE_LIQUIDITY_FROM_JOB = 'REMOVE_LIQUIDITY_FROM_JOB'
export const REMOVE_LIQUIDITY_FROM_JOB_RETURNED = 'REMOVE_LIQUIDITY_FROM_JOB_RETURNED'

export const GET_LIQUIDITY_PAIRS = 'GET_LIQUIDITY_PAIRS'
export const LIQUIDITY_PAIRS_RETURNED = 'LIQUIDITY_PAIRS_RETURNED'

export const GET_JOB_BOND_UNBOND = 'GET_JOB_BOND_UNBOND'
export const JOB_BOND_UNBOND_RETURNED = 'JOB_BOND_UNBOND_RETURNED'
